import React from "react";
import PropTypes from "prop-types";
import _get from "lodash/get";
import moment from "moment-timezone"
// import { Link } from 'react-router-dom'
import { withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import TablePagination from "@material-ui/core/TablePagination";

// import EditIcon from "@material-ui/icons/Edit";
import NotFoundTableRow from "../NotFoundTableRow";
import PersonIcon from "@material-ui/icons/Person";
import PhoneIcon from "@material-ui/icons/LocalPhone";
import EmailIcon from "@material-ui/icons/Email";
import Button from "@material-ui/core/Button";
import ViewAccountDetail from "./ViewAccountDetail";

const styles = theme => ({
    root: {
        width: "98%",
        marginRight: "auto",
        marginLeft: "auto",
        marginTop: theme.spacing(1.5),
        overflowX: "auto"
    },
    table: {
        minWidth: 700
    },
    capitalize: {
        textTransform: "capitalize"
    },
    icon: {
        color: "#009be5",
        fontSize: 16,
        marginBottom: -2,
        width: 25
    },
    title: {
        fontWeight: 600,
        margin: "10px auto 5px"
    },
    EditIcon: {
        color: "#009be5",
        cursor: "pointer"
    },
    dataXLargeRow: {
        minWidth: 200
    },
    dataLargeRow: {
        minWidth: 175
    },
    dataMedRow: {
        minWidth: 125
    },
    dataSmallRow: {
        minWidth: 100
    },
    button: {
        marginTop: 2,
        minWidth: 100
    }
});

class ReduxTableRow extends React.Component {
    render() {
        const { classes, item = {} } = this.props;
        const planDues = _get(item, "plan.dues", []) || [];

        let duesOver = 0;
        let duesMonth = 0;

        planDues.forEach((item) => {
            if (moment(item.date) < moment()) {
                duesOver = duesOver + 1;
            } else if (moment(item.date) <= moment().endOf('month')) {
                duesMonth = duesMonth + 1;
            }
        })

        return (
            <TableRow hover>
                <TableCell component="th" scope="row">
                    <div className={classes.dataLargeRow}>
                        <strong>{item.name}</strong>
                        <div>
                            <PhoneIcon className={classes.icon} />
                            {item.phone}
                        </div>
                        <div>
                            <EmailIcon className={classes.icon} />
                            {item.email}
                        </div>
                    </div>
                </TableCell>
                <TableCell>{item.status}</TableCell>
                <TableCell>
                    {item.contact && item.contact.name && (
                        <div className={classes.dataLargeRow}>
                            {item.contact.name && (
                                <div className={classes.capitalize}>
                                    <PersonIcon className={classes.icon} />
                                    {item.contact.name} ({item.contact.role})
                                </div>
                            )}
                            {item.contact.phone && (
                                <div>
                                    <PhoneIcon className={classes.icon} />{" "}
                                    {item.contact.phone}
                                </div>
                            )}
                            {item.contact.email && (
                                <div>
                                    <EmailIcon className={classes.icon} />
                                    {item.contact.email}
                                </div>
                            )}
                        </div>
                    )}
                </TableCell>
                <TableCell>
                    {(duesOver !== 0) && <div style={{ color: "red" }}>{`Over dues: ${duesOver}`} </div>}
                    {(duesMonth !== 0) && <div style={{ color: "orange" }}>{`Dues this month: ${duesMonth}`} </div>}
                    {(duesMonth === 0 && duesOver === 0) && <div >No dues </div>}
                </TableCell>

                <TableCell>
                    <div className={classes.dataLargeRow}>
                        {item.created && (
                            <div>
                                Created: {this.props.formatDate(item.created)}
                            </div>
                        )}
                        {item.edited && (
                            <div>
                                Edited: {this.props.formatDate(item.edited)}
                            </div>
                        )}
                    </div>
                </TableCell>
                <TableCell>
                    {/* <Button
                        size="small"
                        className={classes.button}
                        variant="contained"
                        disabled={item.status !== "ACTIVE"}
                        color="primary"
                        onClick={e => this.props.handleDetails(item)}
                    >
                        DETAILS
                    </Button> */}
                    {/* <br /> */}
                    <Button
                        size="small"
                        className={classes.button}
                        variant="contained"
                        disabled={item.status !== "ACTIVE"}
                        color="primary"
                        onClick={e => this.props.handleAccountDetails(item)}
                    >
                        DETAILS
                    </Button>
                    <br />
                    <Button
                        size="small"
                        variant="outlined"
                        color="primary"
                        className={classes.button}
                        onClick={e => this.props.handleEdit(item)}
                    >
                        EDIT
                    </Button>
                </TableCell>
            </TableRow>
        );
    }
}

export class AccountTable extends React.Component {
    state = { open: false, selectedAccount: null, order: "asc" };

    handleChangePage = (event, page) => {
        const { limit } = this.props;
        this.props.handleChange({ page, limit });
    };

    handleChangeRowsPerPage = event => {
        const limit = event.target.value;
        const { page } = this.props;
        this.props.handleChange({ page, limit });
    };

    handleDetails = async selectedAccount => {
        this.setState({ loading: true });
        this.setState({ open: true });
        this.props.selectedAccount(selectedAccount);
        const accountId = selectedAccount._id;
        await this.props.getAccountDetails({ accountId });
        this.setState({ loading: false });
    };

    handleClose = () => {
        this.setState({ open: false });
        this.props.selectedAccount(null);
    };

    render() {
        const { eld, classes, items = [], count = 1000 } = this.props;
        const { page, limit } = this.props;

        const { open, loading } = this.state;
        return (
            <React.Fragment>
                <ViewAccountDetail
                    open={open}
                    handleClose={this.handleClose}
                    loading={loading}
                />
                <Paper className={classes.root}>
                    <Table className={classes.table}>
                        <TableHead>
                            <TableRow>
                                <TableCell>Name</TableCell>
                                <TableCell>Status</TableCell>
                                <TableCell>Contact</TableCell>
                                <TableCell>Payment</TableCell>
                                <TableCell>Created</TableCell>
                                <TableCell>Actions</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {Array.isArray(items) &&
                                items.map((item, i) => (
                                    <ReduxTableRow
                                        item={item}
                                        key={i}
                                        eld={eld}
                                        classes={classes}
                                        handleDetails={this.handleDetails}
                                        handleEdit={this.props.handleEdit}
                                        formatDate={this.props.formatDate}
                                        handleAccountDetails={this.props.handleAccountDetails}
                                    />
                                ))}
                        </TableBody>
                    </Table>
                    <NotFoundTableRow items={items} />
                    <TablePagination
                        rowsPerPageOptions={[10, 25, 50, 100, 500, 1000]}
                        component="div"
                        count={count || 0}
                        rowsPerPage={limit}
                        page={page}
                        onPageChange={this.handleChangePage}
                        onRowsPerPageChange={this.handleChangeRowsPerPage}
                    />
                </Paper>
            </React.Fragment>
        );
    }
}

AccountTable.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(styles)(AccountTable);
