import React from "react";
import _get from "lodash/get";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import { getUsers, getUserCount } from "../../actions/user/userActions";
import { formatDate } from "../../actions/momentActions";
import CircularProgress from "@material-ui/core/CircularProgress";
import UserTable from "./UserTable";
import AddForm from "./AddForm";
import ReduxButton from "../../components/ReduxButton";
import SearchBar from "./SearchBar";

const styles = theme => ({
    addWrapper: {
        padding: 10
    },
    searchText: {
        display: "inline-block",
        marginTop: 10,
        marginLeft: 5
    },
    grow: {
        flexGrow: 1,
        textAlign: "center"
    }
});

export class Customer extends React.Component {
    state = {
        open: false,
        users: [],
        loading: false,
        limit: 1,
        page: 0
    };

    async componentDidMount() {
        await this.getUserCount();
        await this.getUsers();
    }

    handleOpen = () => {
        this.setState({ open: true, customer: null });
    };

    getUserCount = async () => {
        try {
            await this.props.getUserCount();
        } catch (e) {}
    };

    getUsers = async () => {
        try {
            this.setState({ loading: true });
            const { page, limit, customerId, status } = this.state;
            const skip = parseInt(page) * parseInt(limit);
            let params = {
                take: limit,
                skip,
                relations: ["customer"]
            };

            if (customerId) {
                params.customerId = customerId;
            }
            if (status) {
                params.status = status;
            }

            const users = await this.props.getUsers();

            if (users && Array.isArray(users)) {
                this.setState({ users });
            }
            this.setState({ loading: false });
        } catch (e) {}
    };

    handleClose = () => {
        this.setState({ open: false });
        this.getUsers();
    };

    handleEdit = customer => {
        this.setState({ open: true, customer });
    };

    handleChange = params => {
        this.setState(params, this.getUsers);
    };

    render() {
        const { classes, count } = this.props;
        const { open, loading, page, limit } = this.state;
        const users = this.state.users || [];
        const user = this.state.user || {};

        return (
            <div>
                {open && (
                    <AddForm
                        open={open}
                        user={user}
                        handleClose={this.handleClose}
                    />
                )}
                <SearchBar
                    onSearch={this.onSearch}
                    showMessageDialog={this.showMessageDialog}
                    loading={loading}
                    addBtnClick={this.handleOpen}
                />
                {loading ? (
                    <div className={classes.grow}>
                        <CircularProgress />
                    </div>
                ) : (
                    <UserTable
                        limit={limit}
                        page={page}
                        count={count}
                        handleEdit={this.handleEdit}
                        items={users}
                        handleChange={this.handleChange}
                        formatDate={this.props.formatDate}
                    />
                )}
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        count: _get(state, "userData.count", 0),
        users: _get(state, "userData.users", [])
    };
};

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            getUsers,
            getUserCount,
            formatDate
        },
        dispatch
    );

export default withStyles(styles)(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(Customer)
);
