import axios from "./axios";
import _get from "lodash/get";
import * as TYPES from "./documentActionTypes";

export const setAllDocuments = value => ({
    type: TYPES.DOCUMENT,
    value
});

export const setDocumentCount = value => ({
    type: TYPES.DOCUMENT_COUNT,
    value
});

export const onDocumentBusy = value => ({
    type: TYPES.DOCUMENT_BUSY,
    value
});

export function getAllDocumentCount(params = {}) {
    return (dispatch, getState) => {
        dispatch(onDocumentBusy(true));
        const BASE_URL = _get(getState(), "appData.BASE_URL");
        const accountId = _get(getState(), "authData.accountId");

        return axios({
            url: `${BASE_URL}/secure/${accountId}/doc/count`,
            method: "get",
            params
        })
            .then(response => {
                const _response = _get(response, "data.count", 0);
                dispatch(setDocumentCount(_response));
                return _response;
            })
            .catch(error => {
                const message = _get(error, "response.data.errors[0].message");
                return message;
            })
            .then(response => {
                dispatch(onDocumentBusy(false));
                return response;
            });
    };
}

export function getAllDocument(params = {}) {
    return (dispatch, getState) => {
        dispatch(onDocumentBusy(true));

        const BASE_URL = _get(getState(), "appData.BASE_URL");
        const accountId = _get(getState(), "authData.accountId");

        return axios({
            url: `${BASE_URL}/secure/${accountId}/doc`,
            method: "get",
            params
        })
            .then(response => {
                const _response = _get(response, "data", {});
                dispatch(setAllDocuments(_response));
                return _response;
            })
            .catch(error => {
                const message = _get(error, "response.data.errors[0].message");
                return message;
            })
            .then(response => {
                dispatch(onDocumentBusy(false));
                return response;
            });
    };
}
