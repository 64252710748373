import React from "react";
import { connect } from "react-redux";
import { reduxForm } from "redux-form";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import CircularProgress from "@material-ui/core/CircularProgress";
import {
    getAllDocumentCount,
    getAllDocument
} from "../../actions/documentActions";
import {
    getStartOfDay,
    getEndOfDay,
    formatDateTime
} from "../../actions/momentActions";
import { downloadImage } from "../../actions/app/appActions";
import { startDay, currTime } from "../../utils/momentHelpers";
import DocumentTable from "./DocumentTable";
import SearchBar from "../../components/SearchBar";

export class Document extends React.Component {
    state = {
        limit: 10,
        page: 0,
        start: startDay,
        end: currTime,
        driverId: "",
        vehicleId: "",
        loading: false
    };
    async componentDidMount() {
        // await this.props.getAllDocumentCount();
        await this.getAllDocument();
    }
    async getAllDocument() {
        this.setState({ loading: true });
        const { limit, page, driverId, start, end } = this.state;
        const skip = parseInt(page) * parseInt(limit);

        await this.props.getAllDocument({
            limit,
            skip,
            driverId,
            from: start ? new Date(this.props.getStartOfDay(start)) : null,
            to: end ? new Date(this.props.getEndOfDay(end)) : null
        });
        this.setState({ loading: false });
    }
    handleChange = ({ limit, page }) => {
        this.setState({ limit, page }, this.getAllDocument);
    };
    handleSearchChange = ({ driverId, vehicleId, start, end }) => {
        this.setState({ driverId, vehicleId, start, end }, this.getAllDocument);
    };

    render() {
        const { documentAll } = this.props;
        const { loading } = this.state;

        return (
            <Grid container spacing={0} style={{ padding: 10 }}>
                <Grid item xs={12} sm={12}>
                    <SearchBar
                        options={{
                            // isEqp: true,
                            isDriver: false,
                            isStartDate: true,
                            isEndDate: true,
                            isTime: false,
                            isTimeperiod: true
                        }}
                        loading={loading}
                        onSearch={this.handleSearchChange}
                    />
                    {loading && (
                        <div style={{ textAlign: "center" }}>
                            <CircularProgress />
                        </div>
                    )}
                    <DocumentTable
                        items={documentAll}
                        count={this.props.count}
                        getAllDocument={this.props.getAllDocument}
                        downloadImage={this.props.downloadImage}
                        formatDateTime={this.props.formatDateTime}
                        limit={this.state.limit}
                        page={this.state.page}
                        handleChange={this.handleChange}
                    />
                </Grid>
            </Grid>
        );
    }
}

reduxForm({
    form: "documentForm"
})(Document);

const mapStateToProps = state => {
    return {
        // documentAll: state.documentData.documentAll,
        // count: state.documentData.count
    };
};

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            downloadImage, getAllDocumentCount, formatDateTime, getAllDocument,
            getStartOfDay,
            getEndOfDay,
        },
        dispatch
    );

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(Document)
);
