import React from "react";
import _get from "lodash/get";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import {
    getEquipmentCount,
    getEquipments
} from "../../actions/equipment/equipmentActions";
import { formatDate } from "../../actions/momentActions";
import CircularProgress from "@material-ui/core/CircularProgress";
import EquipmentsTable from "./EquipmentsTable";
import AddForm from "./AddForm";
import ReduxButton from "../../components/ReduxButton";
import SearchBar from "./SearchBar";

const styles = theme => ({
    addWrapper: {
        padding: 10
    },
    searchText: {
        display: "inline-block",
        marginTop: 10,
        marginLeft: 5
    },
    grow: {
        flexGrow: 1,
        textAlign: "center"
    }
});

export class Equipment extends React.Component {
    state = {
        open: false,
        equipments: [],
        loading: false,
        limit: 25,
        page: 0
    };

    async componentDidMount() {
        await this.getEquipmentCount();
        await this.getEquipments();
    }

    handleOpen = () => {
        this.setState({ open: true, equipment: null });
    };

    getEquipmentCount = async () => {
        try {
            const params = {};
            await this.props.getEquipmentCount(params);
        } catch (e) {}
    };

    getEquipments = async () => {
        try {
            this.setState({ loading: true });
            const {
                page,
                limit,
                eqpName,
                eqpType,
                eqpStatus,
                customerId
            } = this.state;
            const skip = parseInt(page) * parseInt(limit);
            let params = {
                take: limit,
                skip,
                relations: ["customer"]
            };

            if (customerId) {
                params.customerId = customerId;
            }
            if (eqpName) {
                params.name = eqpName;
            }
            if (eqpType) {
                params.type = eqpType;
            }
            if (eqpStatus) {
                params.status = eqpStatus;
            }

            const equipments = await this.props.getEquipments(params);
            if (equipments && Array.isArray(equipments)) {
                this.setState({ equipments });
            }
            this.setState({ loading: false });
        } catch (e) {}
    };

    handleChange = params => {
        this.setState(params, this.getEquipments);
    };

    handleClose = () => {
        this.setState({ open: false });
        this.getEquipments();
    };

    handleEdit = equipment => {
        this.setState({ open: true, equipment });
    };

    onSearch = params => {
        this.setState(params, this.getEquipments);
    };

    render() {
        const { classes, count } = this.props;
        const { equipments, equipment, loading } = this.state;
        const { open, page, limit } = this.state;

        return (
            <div>
                {open && (
                    <AddForm
                        open={open}
                        equipment={equipment}
                        handleClose={this.handleClose}
                    />
                )}
                <SearchBar
                    onSearch={this.onSearch}
                    showMessageDialog={this.showMessageDialog}
                    loading={loading}
                    addBtnClick={this.handleOpen}
                />
                {loading ? (
                    <div
                        className={classes.grow}
                        style={{ textAlign: "center", marginTop: "20px" }}
                    >
                        <CircularProgress />
                    </div>
                ) : (
                    <EquipmentsTable
                        limit={limit}
                        page={page}
                        count={count}
                        handleEdit={this.handleEdit}
                        items={equipments}
                        handleChange={this.handleChange}
                        formatDate={this.props.formatDate}
                    />
                )}
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        count: _get(state, "equipmentData.count", 0),
        equipments: _get(state, "equipmentData.equipments", [])
    };
};

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            getEquipmentCount,
            getEquipments,
            formatDate
        },
        dispatch
    );

export default withStyles(styles)(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(Equipment)
);
