import React from "react";
import _get from "lodash/get";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withStyles } from "@material-ui/core/styles";
import { getInvoice } from "../../actions/invoice/invoiceActions";
import { formatDate } from "../../actions/momentActions";
import CircularProgress from "@material-ui/core/CircularProgress";
import html2pdf from "html2pdf.js";

import AddForm from "./AddForm";
import { Button } from "@material-ui/core";

const styles = theme => ({
    addWrapper: {
        padding: 10
    },
    searchText: {
        display: "inline-block",
        marginTop: 10,
        marginLeft: 5
    },
    grow: {
        flexGrow: 1,
        textAlign: "center"
    }
});

export class Invoice extends React.Component {
    state = {
        open: false,
        invoices: [],
        loading: true,
        limit: 25,
        page: 0
    };

    async componentDidMount() {
        await this.getInvoices();
    }

    handleOpen = () => {
        this.setState({ open: true, invoice: null });
    };

    getInvoices = async () => {
        try {
            this.setState({ loading: true });
            const id = _get(this, "props.match.params.id");

            console.log(":", id)
            const invoice = await this.props.getInvoice(id);
            if (invoice) {
                this.setState({ invoice });
            }
            this.setState({ loading: false });
        } catch (e) { }
    };

    handleClose = () => {
        this.setState({ open: false });
        this.getInvoices();
    };

    handleEdit = invoice => {
        this.setState({ open: true, invoice });
    };

    onSearch = ({ searchStatus, customerId }) => {
        this.setState({ searchStatus, customerId }, () => {
            this.getInvoices();
        })
    };

    showMessageDialog = () => {
        const { invoice } = this.state;
        var element = document.getElementById('element-to-print');
        var opt = {
            margin: 0.2,
            filename: `Invoive${invoice.id}.pdf`,
            image: { type: 'jpeg', quality: 0.98 },
            html2canvas: { scale: 2 },
            jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' }
        };
        // New Promise-based usage:
        html2pdf().set(opt).from(element).save();

    };

    render() {
        const { classes, account } = this.props;
        const { open, invoice, loading } = this.state;
        const id = _get(this, "props.match.params.id");
        const address = account?.address[0] || {}
        return (
            <div>
                {open && (
                    <AddForm
                        open={open}
                        invoice={invoice}
                        handleClose={this.handleClose}
                    />
                )}

                {loading ? (
                    <div className={classes.grow}>
                        <CircularProgress />
                    </div>
                ) : <div>
                    <div style={{ textAlign: "right", padding: 24 }}>
                        <Button onClick={() => { this.setState({ open: true }) }}
                            variant="outlined" color="primary" size="small">Edit</Button>{" "}
                        <Button onClick={() => { this.showMessageDialog() }}
                            variant="contained" color="primary" size="small">Print</Button>
                    </div>
                    <div style={{ width: 1000, minHeight: 1000, margin: "10px auto" }} className="card">
                        <div id="element-to-print" style={{ padding: 20 }}>
                            <div className="invoice-box">
                                <table cellPadding="0" cellSpacing="0">
                                    <tbody>
                                        <tr className="top">
                                            <td colSpan="4">
                                                <table>
                                                    <tbody>
                                                        <tr>
                                                            <td className="title">
                                                                <img src="https://www.sparksuite.com/images/logo.png" style={{ width: "100%", maxWidth: 300 }} alt="" />
                                                            </td>

                                                            <td>
                                                                Invoice #: {id}<br />
                                                                Created: {invoice.createdTm}<br />
                                                                Due: {invoice.dueTm}
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </td>
                                        </tr>

                                        <tr className="information">
                                            <td colSpan="4">
                                                <table>
                                                    <tbody>
                                                        <tr>
                                                            <td>
                                                                {account.name}<br />
                                                                {address.line1}<br />
                                                                {address.city}, {address.state}-{address.zipCode}
                                                            </td>

                                                            <td>
                                                                {invoice.customer?.name}<br />
                                                                {invoice.customer?.email}
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </td>
                                        </tr>

                                        <tr className="heading">
                                            <td>Payment Method</td>
                                            <td></td>
                                            <td></td>
                                            <td>Check #</td>
                                        </tr>

                                        <tr className="details">
                                            <td>Check</td>
                                            <td></td>
                                            <td></td>
                                            <td>1000</td>
                                        </tr>

                                        <tr className="heading">
                                            <td>Item</td>
                                            <td>Price</td>
                                            <td>Quality</td>
                                            <td>Total</td>
                                        </tr>

                                        {invoice.items.map((e, i) => <tr key={i} className={i === invoice.items.length - 1 ? "item last" : "item"}>
                                            <td>{e.desc}</td>
                                            <td>${e.price}</td>
                                            <td>{e.quantity}</td>
                                            <td>${e.amount}</td>
                                        </tr>
                                        )}

                                        <tr className="total">
                                            <td>Total:</td>
                                            <td></td>
                                            <td></td>
                                            <td> ${invoice.rate.amount}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>

                </div>}
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        accountBaseUrl: _get(state, "authData.accountBaseUrl"),
        account: _get(state, "authData.account"),

    };
};

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            getInvoice,
            formatDate
        },
        dispatch
    );

export default withStyles(styles)(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(Invoice)
);
