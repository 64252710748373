import React, { useState, useEffect } from "react";
import _get from "lodash/get";
import moment from "moment-timezone";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withStyles } from "@material-ui/core/styles";
import MenuItem from "@material-ui/core/MenuItem";
import Grid from "@material-ui/core/Grid";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import {
    DatePicker,
    KeyboardDateTimePicker,
    MuiPickersUtilsProvider
} from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import SearchIcon from "@material-ui/icons/Search";
import { getDrivers } from "../../actions/driverActions";
import { getEquipments } from "../../actions/equipmentActions";
import { getAccounts } from "../../actions/account/accountActions";
import {
    formatDate,
    formatTime,
    formatDateTimeSec,
    getStartOfDay,
    getEndOfDay
} from "../../actions/momentActions";
import { isPastDate, searchIftaYear } from "./searchHelpers";
import Button from "@material-ui/core/Button";
import ButtonGroup from "@material-ui/core/ButtonGroup";
// import { addDay } from "../../utils/momentHelpers";

const IFTA_YEARS = searchIftaYear(2)
const styles = theme => ({
    addButtonWrapper: {
        width: "99%",
        textAlign: "right"
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: "100%",
        textAlign: "left"
    },
    grow: {
        flexGrow: 1,
        textAlign: "center"
    },
    buttonGroup: {
        marginTop: 25,
        textAlign: "center"
    }
});

const SearchBar = props => {
    const {
        classes,
        accountId,
        constants,
        trackingId,
        drivers = [],
        loading = false,
        stateMil = false,
        // tracking = false,
        options: {
            isEqp = false,
            isTrailer = false,
            isDriver = false,
            isState = false,
            isDownload = false,
            isStartDate = false,
            isEndDate = false,
            isTime = false,
            isTimeperiod = false,
            formatDate = "MM/DD/yyyy",
            format = "MM/DD/yyyy hh:mm a"
        } = {}
    } = props;

    const allStates = constants.US_STATES;
    const _equipments = props.equipments || [];
    const equipments = isTrailer
        ? _equipments
        : _equipments.filter(item => item.assetType === "VEHICLE");
    const [stateId, setState] = useState("");
    const [driverId, setDriver] = useState("");
    const [vehicleId, setVehicle] = useState("");
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [iftaYears, setIftaYears] = useState();

    const [timeperiod, setTimeperiod] = useState("");
    const [view, setView] = useState("Map");

    let isValid = true;


    if (isStartDate && !startDate) {
        isValid = false;
    }
    if (isEndDate && !endDate) {
        isValid = false;
    }

    // if (tracking) {
    //     setStartDate(moment().startOf('day'))
    //     setEndDate(currTime)

    // }

    useEffect(() => {
        async function fetchData() {
            const { options: { isEqp = false, isDriver = false } } = props
            if (isEqp) {
                await props.getEquipments({ skip: 0, limit: 250 });
            }
            if (isDriver) {
                await props.getDrivers({ skip: 0, limit: 250 });
            }
        }
        setTimeperiod(dateOptions[0].id)
        setStartDate(moment().startOf('day'))
        setEndDate(moment())

        fetchData();
        if (trackingId) {
            setDriver(trackingId)
        }

        // eslint-disable-next-line
    }, [accountId, trackingId]);

    const onSearch = e => {
        e.preventDefault();
        let payload = {};
        if (isDriver) {
            payload.driverId = driverId;
        }
        if (isEqp) {
            payload.vehicleId = vehicleId;
        }
        if (isState) {
            payload.stateId = stateId
        }
        if (isStartDate) {
            payload.start = new Date(startDate);
        }
        if (isEndDate) {
            payload.end = new Date(endDate);
        }

        payload.loadMap = true

        props.onSearch(payload);
    };

    const onCancel = e => {
        e.preventDefault();
        let payload = {};

        if (isDriver) {
            payload.driverId = "";
            setDriver("");
        }
        if (isEqp) {
            payload.vehicleId = "";
            setVehicle("");
        }
        if (isState) {
            payload.stateId = "";
            setState("");
        }

        if (isStartDate) {
            payload.start = null;
            setStartDate(null);
        }
        if (isEndDate) {
            payload.end = null;
            setEndDate(null);
        }
        if (isTimeperiod) {
            payload.end = null;
            setTimeperiod(null);
        }

        props.onSearch(payload);
    };

    const setDates = (value) => {

        const currTime = moment();
        if (value === 1) {
            setStartDate(moment().startOf('day'))
            setEndDate(currTime)
        }
        else if (value === 2) {
            setStartDate(props.getStartOfDay(moment().subtract(1, 'days')))
            setEndDate(props.getEndOfDay(moment().subtract(1, 'days')))

        }
        else if (value === 3) {
            setStartDate(moment().startOf('week'))
            setEndDate(currTime)

        }
        else if (value === 4) {
            setStartDate(moment().startOf('month'))
            setEndDate(currTime)

        }
    }

    const dateOptions = props.dateOptions || [
        { name: "Today", id: 1 },
        { name: "Yesterday", id: 2 },
        { name: "This Week", id: 3 },
        { name: "This Month", id: 4 }
    ];

    const viewOptions = [
        { name: "Map", id: "Map" },
        { name: "Table", id: "Table" },
    ];

    const driversArray = Array.isArray(drivers) && drivers.sort((a, b) => {
        return a.firstName.trim().toLowerCase().localeCompare(b.firstName.trim().toLowerCase());
    });

    return (
        <div style={{ padding: 15 }}>
            <MuiPickersUtilsProvider utils={MomentUtils}>
                <Grid container spacing={1}>
                    {isDriver && (
                        <Grid item sm xs={12}>
                            <FormControl className={classes.formControl}>
                                <InputLabel htmlFor="driverId">
                                    Driver
                                </InputLabel>
                                <Select
                                    className={classes.select}
                                    value={driverId}
                                    onChange={e => setDriver(e.target.value)}
                                    inputProps={{
                                        name: "driverId",
                                        id: "driverId"
                                    }}
                                >
                                    <MenuItem value={""}>All Drivers</MenuItem>
                                    {driversArray.map((item, i) => (
                                        <MenuItem key={i} value={item.id} style={{ textTransform: "capitalize" }}>
                                            {item.firstName} {item.lastName}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                            {props.tracking && !driverId ?
                                <span style={{ color: "red", marginLeft: "10px", fontSize: "14px" }}>Required</span> : null}
                        </Grid>
                    )}
                    {isEqp && (
                        <Grid item sm xs={12}>
                            <FormControl className={classes.formControl}>
                                <InputLabel htmlFor="vehicleId">
                                    All Equipment
                                </InputLabel>
                                <Select
                                    className={classes.select}
                                    value={vehicleId}
                                    onChange={e => setVehicle(e.target.value)}
                                    inputProps={{
                                        name: "vehicleId",
                                        id: "vehicleId"
                                    }}
                                >
                                    <MenuItem value={""}>
                                        All equipments
                                    </MenuItem>
                                    {Array.isArray(equipments) &&
                                        equipments.map((item, i) => (
                                            <MenuItem key={i} value={item.id}>
                                                {item.name}
                                                {isTrailer ? (
                                                    <small
                                                        style={{
                                                            marginLeft: 10
                                                        }}
                                                    >
                                                        {` (${item.assetType})`}{" "}
                                                    </small>
                                                ) : (
                                                    ""
                                                )}
                                            </MenuItem>
                                        ))}
                                </Select>
                            </FormControl>
                        </Grid>
                    )}
                    {isState && (
                        <Grid item sm xs={12}>
                            <FormControl className={classes.formControl}>
                                <InputLabel htmlFor="stateId">
                                    All States
                                </InputLabel>
                                <Select
                                    className={classes.select}
                                    value={stateId}
                                    onChange={e => setState(e.target.value)}
                                    inputProps={{
                                        name: "stateId",
                                        id: "stateId"
                                    }}
                                >
                                    <MenuItem value={""}>All States</MenuItem>
                                    {Array.isArray(allStates) &&
                                        allStates.map((item, i) => (
                                            <MenuItem key={i} value={item.id}>
                                                {item.name}
                                            </MenuItem>
                                        ))}
                                </Select>
                            </FormControl>
                        </Grid>
                    )}

                    {(isTimeperiod && !stateMil) && (
                        <Grid item sm xs={12}>
                            <FormControl className={classes.formControl}>
                                <InputLabel htmlFor="timeperiod">
                                    Time Period
                                </InputLabel>
                                <Select
                                    className={classes.select}
                                    value={timeperiod || ""}
                                    onChange={(e) => {
                                        setTimeperiod(e.target.value);
                                        setDates(e.target.value)
                                    }}
                                    inputProps={{
                                        name: "timeperiod",
                                        id: "timeperiod"
                                    }}
                                >
                                    <MenuItem value={""}>
                                        Time period
                                    </MenuItem>
                                    {Array.isArray(dateOptions) &&
                                        dateOptions.map((item, i) => (
                                            <MenuItem key={i} value={item.id}>
                                                {item.name}
                                            </MenuItem>
                                        ))}
                                </Select>
                            </FormControl>
                        </Grid>
                    )}

                    {(props.tracking) && (
                        <Grid item sm xs={12}>
                            <FormControl className={classes.formControl}>
                                <InputLabel htmlFor="timeperiod">
                                    View
                                </InputLabel>
                                <Select
                                    className={classes.select}
                                    value={view || ""}
                                    onChange={(e) => {
                                        setView(e.target.value);
                                        props.handleView(e.target.value)
                                    }}
                                    inputProps={{
                                        name: "view",
                                        id: "view"
                                    }}
                                >

                                    {Array.isArray(viewOptions) &&
                                        viewOptions.map((item, i) => (
                                            <MenuItem key={i} value={item.id}>
                                                {item.name}
                                            </MenuItem>
                                        ))}
                                </Select>
                            </FormControl>
                        </Grid>
                    )}

                    {stateMil && (
                        <Grid item sm xs={12}>
                            <FormControl className={classes.formControl}>
                                <InputLabel htmlFor="quarter">
                                    Quarter
                                </InputLabel>
                                <Select
                                    className={classes.select}
                                    value={iftaYears || ""}
                                    onChange={(e) => {
                                        setIftaYears(e.target.value);
                                        const year = e.target.value;

                                        if (year) {
                                            const _sel = IFTA_YEARS.find(
                                                item => item.id === year
                                            );
                                            setStartDate(_sel.start)
                                            setEndDate(isPastDate(_sel.end) ? _sel.end : new Date())
                                        }
                                    }
                                    }
                                    inputProps={{
                                        name: "quarter",
                                        id: "quarter"
                                    }}
                                >
                                    <MenuItem value={""}>
                                        quarter
                                    </MenuItem>
                                    {Array.isArray(IFTA_YEARS) &&
                                        (IFTA_YEARS).map((item, i) => (
                                            <MenuItem key={i} value={item.id}>
                                                {item.label}
                                            </MenuItem>
                                        ))}
                                </Select>
                            </FormControl>
                        </Grid>
                    )}

                    {isStartDate && (
                        <Grid item sm xs={12}>
                            <FormControl className={classes.formControl}>
                                {isTime ? (
                                    <KeyboardDateTimePicker
                                        value={startDate}
                                        onChange={(e) => {
                                            setStartDate(e)
                                            setTimeperiod("")
                                            setIftaYears("")
                                        }}
                                        label={"Start Date"}
                                        format={format}
                                    />
                                ) : (
                                    <DatePicker
                                        value={startDate}
                                        onChange={(e) => {
                                            setStartDate(e)
                                            setTimeperiod("")
                                            setIftaYears("")
                                        }}
                                        label={"Start Date"}
                                        format={formatDate}
                                    />
                                )}
                            </FormControl>
                        </Grid>
                    )}
                    {isEndDate && (
                        <Grid item sm xs={12}>
                            <FormControl className={classes.formControl}>
                                {isTime ? (
                                    <KeyboardDateTimePicker
                                        value={endDate}
                                        onChange={(e) => {
                                            setEndDate(e)
                                            setTimeperiod("")
                                            setIftaYears("")
                                        }}
                                        label="End Date"
                                        format={format}
                                    />
                                ) : (
                                    <DatePicker
                                        value={endDate}
                                        onChange={(e) => {
                                            setEndDate(e)
                                            setTimeperiod("")
                                            setIftaYears("")
                                        }}
                                        label="End Date"
                                        format={formatDate}
                                    />
                                )}
                            </FormControl>
                        </Grid>
                    )}
                    <Grid item sm xs={12}>
                        <div className={classes.buttonGroup}>
                            <ButtonGroup
                                size="small"
                                disableElevation
                                variant="contained"
                                color="primary"
                            >
                                <Button
                                    color="primary"
                                    variant="contained"
                                    startIcon={<SearchIcon />}
                                    disabled={(!isValid || props.loading) || (props.tracking && !driverId)}
                                    onClick={onSearch}
                                    ref={props.refrence}
                                >
                                    {loading ? "Searching..." : "SEARCH"}
                                </Button>
                                <Button color="secondary" onClick={onCancel}>
                                    CANCEL
                                </Button>
                                {isDownload ? (
                                    <Button
                                        color="primary"
                                        onClick={props.onDownload}
                                    >
                                        DOWNLOAD
                                    </Button>
                                ) : null}
                            </ButtonGroup>
                        </div>
                    </Grid>
                </Grid>
            </MuiPickersUtilsProvider>
        </div>
    );
    // }
};

const mapStateToProps = state => {
    return {
        drivers: _get(state, "driverData.drivers", []),
        equipments: _get(state, "equipmentData.equipments", []),
        accounts: _get(state, "accountData.accounts", []),
        constants: _get(state, "appData.constants", {})
    };
};

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            getDrivers,
            getEquipments,
            getAccounts,
            formatDate,
            formatTime,
            formatDateTimeSec,
            getStartOfDay,
            getEndOfDay
        },
        dispatch
    );

export default withStyles(styles)(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(SearchBar)
);
