import axios from "./axios";
import _get from "lodash/get";
import * as TYPES from "./driverActionTypes";

export const setDrivers = value => ({
    type: TYPES.DRIVERS,
    value
});

export const updateSelectedDriver = value => ({
    type: TYPES.SELECTED_DRIVER,
    value
});

export const appDriverBusy = value => ({
    type: TYPES.APP_DRIVER_BUSY,
    value
});

export const setDriversCount = value => ({
    type: TYPES.DRIVER_COUNT,
    value
});

export const setDriversRealTime = value => ({
    type: TYPES.DRIVER_REAL_TIME,
    value
});

export function getDriversRealTime(params = {}) {
    return (dispatch, getState) => {
        const STATUS_BASE_URL = _get(getState(), "appData.STATUS_BASE_URL");
        const accountId = _get(getState(), "authData.profile.user.accountId");

        dispatch(appDriverBusy(true));

        return axios({
            url: `${STATUS_BASE_URL}/manager/${accountId}/realtime`,
            method: "get",
            params
        })
            .then(response => {
                const _response = _get(response, "data", {});
                dispatch(setDriversRealTime(_response));
                return _response;
            })
            .catch(error => {
                const message = _get(error, "response.data.errors[0].message");
                return message;
            })
            .then(response => {
                dispatch(appDriverBusy(false));
                return response;
            });
    };
}

export function getDriversRealTimeBeta(params = {}) {
    return (dispatch, getState) => {
        const BASE_URL = _get(getState(), "appData.REALTIME_URL");
        dispatch(appDriverBusy(true));

        return axios({
            url: `${BASE_URL}/realtime`,
            method: "get",
            params
        })
            .then(response => {
                const _response = _get(response, "data", []);
                dispatch(setDriversRealTime(_response));
                return _response;
            })
            .catch(error => {
                const message = _get(error, "response.data.errors[0].message");
                return message;
            })
            .then(response => {
                dispatch(appDriverBusy(false));
                return response;
            });
    };
}

export function getDriversCount(params = {}) {
    return (dispatch, getState) => {
        const BASE_URL = _get(getState(), "appData.BASE_URL");
        const accountId = _get(getState(), "authData.profile.user.accountId");

        dispatch(appDriverBusy(true));

        return axios({
            url: `${BASE_URL}/manager/${accountId}/drivers/count`,
            method: "get",
            params
        })
            .then(response => {
                const _response = _get(response, "data", {});
                dispatch(setDriversCount(_response.count));
                return _response;
            })
            .catch(error => {
                const message = _get(error, "response.data.errors[0].message");
                return message;
            })
            .then(response => {
                dispatch(appDriverBusy(false));
                return response;
            });
    };
}

export function getDrivers(params = {}) {
    return (dispatch, getState) => {
        const BASE_URL = _get(getState(), "appData.BASE_URL");
        const accountId = _get(getState(), "authData.profile.user.accountId");

        dispatch(appDriverBusy(true));

        return axios({
            url: `${BASE_URL}/manager/${accountId}/drivers`,
            method: "get",
            params
        })
            .then(response => {
                const _response = _get(response, "data", {});
                dispatch(setDrivers(_response));
                return _response;
            })
            .catch(error => {
                const message = _get(error, "response.data.errors[0].message");
                return message;
            })
            .then(response => {
                dispatch(appDriverBusy(false));
                return response;
            });
    };
}

export function getDriverById(params = {}) {
    return (dispatch, getState) => {
        const BASE_URL = _get(getState(), "appData.BASE_URL");
        const accountId = _get(getState(), "authData.profile.user.accountId");

        dispatch(appDriverBusy(true));

        return axios({
            url: `${BASE_URL}/manager/${accountId}/drivers/${params.driverId}`,
            method: "get",
            params
        })
            .then(response => {
                const _response = _get(response, "data", {});
                dispatch(updateSelectedDriver(_response));
                return _response;
            })
            .catch(error => {
                const message = _get(error, "response.data.errors[0].message");
                return message;
            })
            .then(response => {
                dispatch(appDriverBusy(false));
                return response;
            });
    };
}
