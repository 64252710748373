import React from "react";
import _get from "lodash/get";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import { getEquipments } from "../../actions/equipment/equipmentActions";
import { formatDate } from "../../actions/momentActions";
import EquipmentList from "./EquipmentList";
import { CircularProgress } from "@material-ui/core";
import Paper from "@material-ui/core/Paper";

const styles = theme => ({
    addWrapper: {
        padding: 10
    },
    searchText: {
        display: "inline-block",
        marginTop: 10,
        marginLeft: 5
    },
    grow: {
        flexGrow: 1,
        textAlign: "center"
    }
});

export class Dashboard extends React.Component {
    state = {
        open: false,
        equipments: [],
        loading: true,
        limit: 25,
        page: 0
    };

    async componentDidMount() {
        await this.getEquipments();
    }

    handleOpen = () => {
        this.setState({ open: true, equipment: null });
    };

    getEquipments = async () => {
        try {
            this.setState({ loading: true });
            const { page, limit, eqpStatus } = this.state;
            const skip = parseInt(page) * parseInt(limit);
            let params = {
                take: limit,
                skip,
                relations: ["customer"]
            };

            if (eqpStatus) {
                params.status = eqpStatus;
            }

            const equipments = await this.props.getEquipments(params);
            if (equipments && Array.isArray(equipments)) {
                this.setState({ equipments });
            }
            this.setState({ loading: false });
        } catch (e) {}
    };

    handleChange = params => {
        this.setState(params, this.getEquipments);
    };

    handleClose = () => {
        this.setState({ open: false });
        this.getEquipments();
    };

    handleEdit = equipment => {
        this.setState({ open: true, equipment });
    };

    onSearch = params => {
        this.setState(params, this.getEquipments);
    };

    render() {
        const { classes, constants } = this.props;
        const equipments = this.state.equipments || [];

        const equipmentsIr = equipments.filter(
            item => item.status === "UNDER_REPAIR"
        );
        const equipmentsOs = equipments.filter(
            item => item.status === "OUT_OF_SERVICE"
        );
        const equipmentsPs = equipments.filter(
            item => item.status === "PARTS_ORDERED"
        );
        const equipmentsCo = equipments.filter(
            item => item.status === "COMPLETE"
        );
        return (
            <div>
                {this.state.loading ? (
                    <div className={classes.grow}>
                        <CircularProgress />{" "}
                    </div>
                ) : (
                    <Grid container spacing={0} className={classes.addWrapper}>
                        <Grid item md={3} xs={12}>
                            <Paper elevation={3} style={{ padding: 5 }}>
                                <h4 style={{ marginLeft: 20 }}>
                                    Out Of Service
                                </h4>
                                <EquipmentList
                                    equipments={equipmentsOs}
                                    constants={constants}
                                />
                            </Paper>
                        </Grid>
                        <Grid item md={3} xs={12} style={{ padding: 5 }}>
                            <Paper elevation={3}>
                                <h4 style={{ marginLeft: 20 }}>Under Repair</h4>
                                <EquipmentList
                                    equipments={equipmentsIr}
                                    constants={constants}
                                />
                            </Paper>
                        </Grid>
                        <Grid item md={3} xs={12} style={{ padding: 5 }}>
                            <Paper elevation={3}>
                                <h4 style={{ marginLeft: 20 }}>
                                    Parts Ordered
                                </h4>
                                <EquipmentList
                                    equipments={equipmentsPs}
                                    constants={constants}
                                />
                            </Paper>
                        </Grid>
                        <Grid item md={3} xs={12} style={{ padding: 5 }}>
                            <Paper elevation={3}>
                                <h4 style={{ marginLeft: 20 }}>Complete</h4>
                                <EquipmentList
                                    equipments={equipmentsCo}
                                    constants={constants}
                                />
                            </Paper>
                        </Grid>
                    </Grid>
                )}
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        equipments: _get(state, "equipmentData.equipments", []),
        constants: _get(state, "appData.constants", {})
    };
};

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            getEquipments,
            formatDate
        },
        dispatch
    );

export default withStyles(styles)(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(Dashboard)
);
