import React from "react";
import PropTypes from "prop-types";
import _get from "lodash/get";
import { withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import EditIcon from "@material-ui/icons/Edit";
import NotFoundTableRow from "../NotFoundTableRow";
import TablePagination from "@material-ui/core/TablePagination";

const styles = theme => ({
    root: {
        width: "98%",
        marginRight: "auto",
        marginLeft: "auto",
        marginTop: theme.spacing(1),
        overflowX: "auto"
    },
    table: {
        minWidth: 700
    },
    capitalize: {
        textTransform: "capitalize"
    },
    icon: {
        color: "#009be5",
        fontSize: 16,
        marginBottom: -2,
        width: 25
    },
    title: {
        fontWeight: 600,
        margin: "10px auto 5px"
    },
    EditIcon: {
        color: "#009be5",
        cursor: "pointer"
    },
    dataXLargeRow: {
        minWidth: 200
    },
    dataLargeRow: {
        minWidth: 175
    },
    dataMedRow: {
        minWidth: 125
    },
    dataSmallRow: {
        minWidth: 100
    }
});

class ReduxTableRow extends React.Component {
    render() {
        const { classes, item = {} } = this.props;
        const role = _get(item, "roles[0].userRole.name");
        const scope = _get(item, "scopes[0].userScope.name");
        return (
            <TableRow hover>
                <TableCell>
                    {item.lastName}, {item.firstName}
                    {item.customer && <div>{item.customer.name}</div>}
                </TableCell>
                <TableCell>{item.email}</TableCell>
                <TableCell>{item.status}</TableCell>
                <TableCell>{scope}</TableCell>
                <TableCell>{role}</TableCell>
                <TableCell>
                    <EditIcon
                        className={classes.EditIcon}
                        onClick={e => this.props.handleEdit(item)}
                    />
                </TableCell>
            </TableRow>
        );
    }
}

export class CarrierTable extends React.Component {
    handleChangePage = (event, page) => {
        const { limit } = this.props;
        this.props.handleChange({ page, limit });
    };
    handleChangeRowsPerPage = event => {
        const limit = event.target.value;
        const { page } = this.props;
        this.props.handleChange({ page, limit });
    };
    render() {
        const { classes, items = [], handleEdit } = this.props;
        const { count, limit, page } = this.props;

        return (
            <Paper className={classes.root}>
                <Table className={classes.table} page={10}>
                    <TableHead>
                        <TableRow>
                            <TableCell>Name</TableCell>
                            <TableCell>Email</TableCell>
                            <TableCell>Status</TableCell>
                            <TableCell>Scope</TableCell>
                            <TableCell>Role</TableCell>
                            <TableCell />
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {Array.isArray(items) &&
                            items.map((item, idx) => (
                                <ReduxTableRow
                                    item={item}
                                    key={idx}
                                    classes={classes}
                                    handleEdit={handleEdit}
                                    formatDate={this.props.formatDate}
                                />
                            ))}
                    </TableBody>
                </Table>
                <NotFoundTableRow items={items} />
                <TablePagination
                    rowsPerPageOptions={[1, 10, 25, 50, 100, 500, 1000]}
                    component="div"
                    count={count || 0}
                    rowsPerPage={limit}
                    page={page}
                    onPageChange={this.handleChangePage}
                    onRowsPerPageChange={this.handleChangeRowsPerPage}
                />
            </Paper>
        );
    }
}

CarrierTable.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(styles)(CarrierTable);
