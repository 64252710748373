import { combineReducers } from "redux";
import appData from "./app";
import authData from "./auth";
import customerData from "../customer/customerReducers";
import equipmentData from "../equipment/equipmentReducers";
import userData from "../user/userReducers";

export default combineReducers({
    appData,
    authData,
    userData,
    customerData,
    equipmentData
});
