import React from "react";
import _get from "lodash/get";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import { Formik, Form } from "formik";
import {
    createTodo,
    updateTodo
} from "../../actions/todo/todoActions";
import { getCustomers } from "../../actions/customer/customerActions";
import { getUsers } from "../../actions/userActions";
import { getEquipments } from "../../actions/equipment/equipmentActions";
import { validate } from "./validate";
import Grid from "@material-ui/core/Grid";
import InputBox from "../../components/InputBox";
import SelectBox from "../../components/SelectBox";
import InputDateBox from "../../components/InputDateBox";

import Error from "../Error";
import Success from "../Success";
import { ALL_TODO_TYPES } from "../../constants";

const styles = theme => ({
    wrapper: {},
    centerButtons: {
        padding: 20,
        textAlign: "center"
    },
    closeButton: {
        position: "absolute",
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500]
    },
    DialogActions: {
        padding: 20,
        justifyContent: "center",
        flexGrow: 1
    }
});

export class AddForm extends React.Component {
    state = {
        loading: false
    };

    componentDidMount() {
        this.getCustomers()
        this.getUsers()
        this.getEquipments()
    }

    getCustomers = async () => {
        try {
            const customers = await this.props.getCustomers();
            if (customers && Array.isArray(customers)) {
                this.setState({ customers });
            }
        } catch (e) { }
    };
    getUsers = async () => {
        try {
            const users = await this.props.getUsers();
            if (users && Array.isArray(users)) {
                this.setState({ users });
            }
        } catch (e) { }
    };
    getEquipments = async () => {
        try {
            const equipments = await this.props.getEquipments();
            if (equipments && Array.isArray(equipments)) {
                this.setState({ equipments });
            }
        } catch (e) { }
    };


    handleSubmit = async values => {
        // console.log(values);
        const todo = this.props.todo || {};
        this.setState({ loading: true });
        try {
            values.accountId = this.props.accountId

            let response;
            if (todo && todo.id) {
                values.id = todo.id;
                response = await this.props.updateTodo(values);
            } else {
                response = await this.props.createTodo(values);
            }

            if (response && response.id) {
                this.setState({ successMessage: "Saved sucessfully" });
                setTimeout(() => this.props.handleClose(), 1000);
            } else {
                this.setState({
                    errorMessage: "Not able to save, please again."
                });
            }
        } catch (e) {
            console.log(e);
            this.setState({ errorMessage: "Not able to save, please again." });
        } finally {
            this.setState({ loading: false });
        }
    };

    render() {
        const { classes } = this.props;
        const todo = this.props.todo || {};
        const { loading, errorMessage, successMessage } = this.state;
        const { customers = [], users = [], equipments = [] } = this.state;
        const isSmallScreen = window.innerWidth < 400;

        const initialValues = {
            accountId: "",
            customerId: "",
            status: "NEW",
            detail: [""],
            equipments: todo.todoEquipments || [{}],
            assignedTo: "",
            dueTm: null,
            ...todo
        };

        return (
            <Dialog
                fullScreen={isSmallScreen}
                fullWidth={true}
                maxWidth={"lg"}
                className={classes.wrapper}
                open={this.props.open}
                onClose={this.props.handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    <Typography>
                        {todo.id ? "EDIT TODO" : "ADD TODO"}
                    </Typography>
                    <IconButton
                        aria-label="Close"
                        className={classes.closeButton}
                        onClick={this.props.handleClose}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <Formik
                        initialValues={initialValues}
                        validate={validate}
                        onSubmit={this.handleSubmit}
                        enableReinitialize={true}
                    >
                        {({ values, handleSubmit, setFieldValue, errors }) => {
                            return (
                                <Form style={{ padding: "20px 10px" }}>
                                    <Grid container spacing={3}>
                                        <SelectBox
                                            md={3}
                                            name="status"
                                            label="Status"
                                            selLabel={"name"}
                                            items={ALL_TODO_TYPES}
                                        />
                                        <SelectBox
                                            md={3}
                                            name="customerId"
                                            label="Customer"
                                            selLabel={"name"}
                                            items={customers}
                                        />
                                        <SelectBox
                                            md={3}
                                            name="assignedTo"
                                            label="Assigned To"
                                            selLabel={"id"}
                                            items={users}
                                        />
                                        <InputDateBox
                                            md={3}
                                            name="dueTm"
                                            minDate={Date()}
                                            label="Due"
                                        />
                                    </Grid>
                                    <Grid container spacing={3} style={{ marginTop: "12px" }}>
                                        <Grid item md={6}>
                                            <Button
                                                variant="outlined"
                                                size="small"
                                                color="primary"
                                                onClick={() => {
                                                    const arr = values.detail
                                                    arr.push("")
                                                    setFieldValue("detail", arr)
                                                }}>Add Detail</Button>
                                            {values.detail.map((e, index) => <Grid container spacing={3} key={index}>
                                                <InputBox name={"detail" + index + 1} label={"Detail " + (index + 1)} md={10}
                                                    value={e}
                                                    onChange={(ev) => {
                                                        values.detail[index] = ev.target.value
                                                        setFieldValue("detail", values.detail)
                                                    }} />
                                                <Button
                                                    size="small"
                                                    variant="text"
                                                    color="secondary"
                                                    onClick={() => {
                                                        const arr = values.detail
                                                        arr.splice(index, 1);
                                                        setFieldValue("detail", arr)
                                                    }} >Delete</Button>
                                            </Grid>
                                            )}
                                        </Grid>
                                        <Grid item md={6}>
                                            <Button
                                                variant="outlined"
                                                size="small"
                                                color="primary"
                                                onClick={() => {
                                                    const arr = values.equipments
                                                    arr.push({})
                                                    setFieldValue("equipments", arr)
                                                }}>Add Equipment</Button>
                                            {values.equipments.map((e, index) =>
                                                <Grid container spacing={3} key={index}>
                                                    <SelectBox
                                                        name={"equipmentId" + index + 1}
                                                        label={"Equipment " + (index + 1)} md={5}
                                                        items={equipments}
                                                        selLabel={"name"}

                                                        value={e.equipmentId} onChange={(e) => {
                                                            const eq = values.equipments[index] || {}
                                                            eq.equipmentId = e.target.value
                                                            values.equipments[index] = eq
                                                            setFieldValue("equipments", values.equipments)
                                                        }} />
                                                    <InputBox name={"equipmentDetail" + index + 1} label={"Equipment Detail " + (index + 1)} md={5}
                                                        value={e.detail} onChange={(e) => {
                                                            const eq = values.equipments[index] || {}
                                                            eq.detail = e.target.value
                                                            values.equipments[index] = eq
                                                            setFieldValue("equipments", values.equipments)
                                                        }} />
                                                    <Button
                                                        size="small"
                                                        variant="text"
                                                        color="secondary"
                                                        onClick={() => {
                                                            const arr = values.equipments
                                                            arr.splice(index, 1);
                                                            setFieldValue("equipments", arr)
                                                        }} >Delete</Button>
                                                </Grid>
                                            )}
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={3}>
                                        {successMessage && (
                                            <Success message={successMessage} />
                                        )}
                                        {errorMessage && (
                                            <Error message={errorMessage} />
                                        )}
                                    </Grid>
                                    <DialogActions
                                        className={classes.DialogActions}
                                    >
                                        <Button
                                            type="submit"
                                            variant="contained"
                                            color="primary"
                                            disabled={loading}
                                        >
                                            {loading ? "Saving..." : "SAVE"}
                                        </Button>
                                        <Button
                                            variant="contained"
                                            onClick={this.props.handleClose}
                                        >
                                            CLOSE
                                        </Button>
                                    </DialogActions>
                                    {/* </Grid> */}
                                </Form>
                            );
                        }}
                    </Formik>
                </DialogContent>
            </Dialog>
        );
    }
}

const mapStateToProps = state => {
    return {
        accountId: _get(state, "authData.accountId"),
        constants: _get(state, "appData.constants", {}),
        errorMessage: _get(state, "equipmentData.error", ""),
        loading: _get(state, "appData.appBusy", false),
        carrierValues: _get(state, "form.equipmentForm.values", {}),
        carriers: _get(state, "carrierData.carriers", []),
        bucket: _get(state, "appData.S3_BUCKET")
    };
};

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            createTodo,
            updateTodo,
            getCustomers,
            getUsers,
            getEquipments
        },
        dispatch
    );

export default withStyles(styles)(
    withRouter(
        connect(
            mapStateToProps,
            mapDispatchToProps
        )(AddForm)
    )
);
